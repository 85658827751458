<template>
  <nav v-if="!isWebView" class="flex gap-x-2 justify-center py-8 font-medium">
    <router-link to="/">Home</router-link>
    <router-link to="/turtle">Turtle - Our App</router-link>
    <router-link to="/about">About Us</router-link>
  </nav>
</template>

<script>
export default {
  name: "TopNav",
  data() {
    return {
      isWebView: false,
    };
  },
  beforeMount() {
    const [, params] = window.location.toString().split("#")[1].split("?");
    const urlParams = new URLSearchParams(params);
    if (urlParams.has("webview")) this.isWebView = true;
  },
};
</script>