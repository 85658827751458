<template>
  <p class="text-center cursor-not-allowed my-6">© Granfield {{ year }}</p>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return { year: new Date().getFullYear() };
  },
};
</script>