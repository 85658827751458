import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Turtle from '../views/Turtle.vue'
import Terms from '../views/turtle/Terms.vue'
import Privacy from '../views/turtle/Privacy.vue'
import Rules from '../views/turtle/Rules.vue'
import About from '../views/About.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/turtle',
    name: 'Turtle',
    component: Turtle,
    children: [
    ]
  },
  {
    path: '/turtle/terms',
    name: 'Terms of Service',
    component: Terms
  },
  {
    path: '/turtle/privacy',
    name: 'Privacy Policy',
    component: Privacy
  },
  {
    path: '/turtle/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
