<template>
  <back-to-turtle />
  <div class="flex-col items-center space-y-3 my-6 px-6">
    <h1 class="text-xl font-bold text-center">Game Rules</h1>
    <h2 class="text-lg font-bold">Detailed Rules</h2>
    <p>
      Here are the detailed game rules of the simulated investment</p>
    <p>
      <ul class="list-disc px-6">
        <li>NMV cannot exceed 15% of BP</li>
        <li>GMV cannot exceed 100m</li>
        <li>No equities with a market cap lower than 150m</li>
        <li>Single position's max market value cannot exceed 5% of BP</li>
        <li>Equity only investment</li>
      </ul>
    </p> 
    <h2 class="text-lg font-bold">Terminology</h2>
    <p>
      <ul class="list-disc px-6">
        <li>GMV: Gross Market Value, sum of all positions' absolute market value</li>
        <li>NMV: Net Market Value, sum of all positions' market value</li>
        <li>BP: Buying Power, maxium allowed GMV</li>
        <li>Market Cap: Outstanding shares multiple price in current exchange</li>
      </ul>
    </p>    
  </div>
</template>


<script>
import BackToTurtle from "../../components/BackToTurtle.vue";

export default {
  name: "Rules",
  components: { BackToTurtle },
};
</script>


