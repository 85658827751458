<template>
  <back-to-turtle />
  <div class="flex-col items-center space-y-3 my-6 px-6">
    <h1 class="text-xl font-bold text-center">Privacy Policy</h1>
    <p>
      This page informs you of our policies regarding the collection, use and
      disclosure of Personal Information we receive from users of the app.
    </p>
    <h2 class="text-lg font-bold">Information Collection And Use</h2>
    <p>
      While using our app, we may ask you to provide us certain personally
      identifiable information that can be used to contact or identify you.
      Personally identifiable information may include, but is not limited to
      your name, email address.
    </p>
    <h2 class="text-lg font-bold">Log Data</h2>
    <p>
      We collect information whenever you use the app, this log data may include
      your IP address, system info, device type, etc. In addition, we may use
      third party services such as Google Analytics that collect, monitor and
      analyze those data.
    </p>
    <h2 class="text-lg font-bold">Communication</h2>
    <p>
      We may use your Personal Information to contact you with in-app
      notifications or emails.
    </p>
    <h2 class="text-lg font-bold">Security</h2>
    <p>
      The security of your Personal Information is important to us, but remember
      that no method of transmission over the Internet, or method of electronic
      storage, is 100% secure. While we strive to use commercially acceptable
      means to protect your Personal Information, we cannot guarantee its
      absolute security.
    </p>
    <h2 class="text-lg font-bold">Changes To This Privacy Policy</h2>
    <p>
      This Privacy Policy is effective as of Sep 1 2021 and will remain in
      effect except with respect to any changes in its provisions in the future,
      which will be in effect immediately after being posted on this page. We
      reserve the right to update or change our Privacy Policy at any time and
      you should check this Privacy Policy periodically. Your continued use of
      the Service after we post any modifications to the Privacy Policy on this
      page will constitute your acknowledgment of the modifications and your
      consent to abide and be bound by the modified Privacy Policy. If we make
      any material changes to this Privacy Policy, we will notify you either
      through the email address you have provided us, or by placing a prominent
      notice on our website.
    </p>
    <h2 class="text-lg font-bold">Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy, please contact us.
    </p>
  </div>
</template>


<script>
import BackToTurtle from "../../components/BackToTurtle.vue";

export default {
  name: "Privacy Policy",
  components: { BackToTurtle },
};
</script>