<template>
  <router-link v-if="!isWebView" to="/turtle">Go back</router-link>
</template>

<script>
export default {
  name: "BackToTurtle",
  data() {
    return {
      isWebView: false,
    };
  },
  beforeMount() {
    const [, params] = window.location.toString().split("#")[1].split("?");
    const urlParams = new URLSearchParams(params);
    if (urlParams.has("webview")) this.isWebView = true;
  },
};
</script>