<template>
  <back-to-turtle />
  <div class="flex-col items-center space-y-3 my-6 px-6">
    <h1 class="text-xl font-bold text-center">Terms of Service</h1>
    <p>
      Please read these Terms of Service ("Terms", "Terms of Service") carefully
      before using the Turtle mobile application (the "Service") operated by
      Granfield Limited ("us", "we", or "our").
    </p>
    <p>
      Your access to and use of the Service is conditioned on your acceptance of
      and compliance with these Terms. These Terms apply to all visitors, users
      and others who access or use the Service.
    </p>
    <p>
      By accessing or using the Service you agree to be bound by these Terms. If
      you disagree with any part of the terms then you may not access the
      Service.
    </p>
    <h2 class="text-lg font-bold">Data</h2>
    <p>
      Our Service allows you to submit certain information within the app, we
      will collect and analyze the data, and potentially share with third
      parties when necessary.
    </p>
    <h2 class="text-lg font-bold">Termination</h2>
    <p>
      We may terminate or suspend access to our Service immediately, without
      prior notice or liability, for any reason whatsoever, including without
      limitation if you breach the Terms. All provisions of the Terms which by
      their nature should survive termination shall survive termination,
      including, without limitation, ownership provisions, warranty disclaimers,
      indemnity and limitations of liability.
    </p>
    <h2 class="text-lg font-bold">Changes</h2>
    <p>
      We reserve the right, at our sole discretion, to modify or replace these
      Terms at any time. If a revision is material we will try to provide at
      least 7 days' notice prior to any new terms taking effect. What
      constitutes a material change will be determined at our sole discretion.
    </p>
    <h2 class="text-lg font-bold">Contact Us</h2>
    <p>If you have any questions about these Terms, please contact us.</p>
  </div>
</template>

<script>
import BackToTurtle from "../../components/BackToTurtle.vue";

export default {
  name: "Terms of Service",
  components: { BackToTurtle },
};
</script>