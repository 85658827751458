<template>
  <div id="container">
    <top-nav />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import TopNav from "./components/TopNav.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  name: "App",
  components: {
    TopNav,
    AppFooter,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app a {
  @apply underline;
}

#app a.router-link-exact-active {
  @apply underline text-green-700 font-bold;
}
</style>
