<template>
  <div class="flex flex-col items-center space-y-6">
    <a class="block" href="https://apps.apple.com/app/turtle-trading-competition/id1579515832"
      ><img src="@/assets/app-store-badge.svg" width="250" alt=""
    /></a>
    <a class="block" href="https://play.google.com/store/apps/details?id=org.granfield.turtle"
      ><img src="@/assets/google-play-badge.svg" width="250" alt=""
    /></a>
    <p>
      Any issues with our app?
      <a :href="'mailto:' + supportEmail">Click here to email us</a>
    </p>
  </div>
  <div class="container">
    <nav class="flex gap-x-2 justify-center py-8 font-light">
      <router-link to="/turtle/rules">Game Rules</router-link>
      <router-link to="/turtle/terms">Terms of Service</router-link>
      <router-link to="/turtle/privacy">Privacy Policy</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supportEmail: "support@granfield.org",
    };
  },
};
</script>
